var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mb-7"},[_c('h1',[_vm._v("Raffles")]),_c('b-alert',{attrs:{"variant":"success","dismissible":"","fade":""},model:{value:(_vm.success.model),callback:function ($$v) {_vm.$set(_vm.success, "model", $$v)},expression:"success.model"}},[_vm._v(" "+_vm._s(_vm.success.text)+" ")]),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","fade":""},model:{value:(_vm.alert.model),callback:function ($$v) {_vm.$set(_vm.alert, "model", $$v)},expression:"alert.model"}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),_c('div',{staticClass:"nav-container"},[_c('b-form-input',{staticClass:"nav-container__item search",attrs:{"placeholder":"Enter search","debounce":"300"},model:{value:(_vm.form.input),callback:function ($$v) {_vm.$set(_vm.form, "input", $$v)},expression:"form.input"}}),(_vm.showCreateRaffle)?_c('CreateRaffleModalV2',{staticClass:"nav-container__item"}):_vm._e(),(_vm.showCopyRaffle)?_c('CopyRaffleModalV2',{staticClass:"nav-container__item"}):_vm._e(),(_vm.showRequestNewRaffle)?_c('RequestNewRaffleBuildModal',{staticClass:"nav-container__item neg-mb"},[_vm._v(" Start a ")]):_vm._e(),_c('LoadingButton',{staticClass:"nav-container__item",attrs:{"variant":"outline"},on:{"onClick":function($event){return _vm.toggleView()}}},[(this.tableView)?_c('Font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'grid']}}):_c('Font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'table-list']}}),_vm._v(" "+_vm._s(this.tableView ? 'View Cards' : 'View Table')+" ")],1),_c('GenericSelectBox',{staticClass:"nav-container__item nav-container__item--select",attrs:{"id":"PageSize","options":[
        { name: '12 Per Page', value: 12 },
        { name: '24 Per Page', value: 24 },
        { name: '36 Per Page', value: 36 },
        { name: '48 Per Page', value: 48 }
      ],"value":String(_vm.pagination.pageSize)},on:{"change":_vm.onPageSizeChange}})],1),(_vm.tableView)?_c('div',{staticClass:"max-width"},[_c('GenericTable',{staticClass:"raffles",attrs:{"columns":_vm.columns,"data":_vm.events,"pagination":_vm.pagination,"loading":_vm.loading},on:{"sort":_vm.sort,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"id",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.number)+" ")]}},{key:"name",fn:function(ref){
      var row = ref.row;
return [_c('router-link',{staticClass:"text-gray-800 underline",attrs:{"to":{ path: "raffle?id=" + row.id }}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"endDate",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.endDate)))+" ")]}},{key:"jackpot",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(row.jackpot))+" ")]}},{key:"status",fn:function(ref){
      var row = ref.row;
return [_c('EventStatusBadge',{attrs:{"status":row.status}})]}},{key:"shortlink",fn:function(ref){
      var row = ref.row;
return [_c('a',{staticClass:"text-gray-800 underline",attrs:{"href":_vm.raffleboxUrl + '/raffle/' + row.shortlink,"target":"top"}},[_vm._v(" "+_vm._s(row.shortlink)+" ")])]}}],null,false,335203322)})],1):_c('div',[(!_vm.events && !_vm.alert.modal)?_c('b-spinner'):_vm._e(),_c('b-card-group',_vm._l((_vm.events),function(event){return _c('b-link',{key:event.id,staticClass:"raffle-card__link",attrs:{"href":'/raffle?id=' + event.id}},[_c('b-card',{staticClass:"raffle-card",attrs:{"img-src":_vm.imageUrl(event.logoUrl),"img-alt":event.name,"no-body":""}},[_c('b-card-body',{staticClass:"flex flex-column justify-between"},[_c('div',[_c('p',{staticClass:"raffle-name"},[_c('strong',[_vm._v(_vm._s(event.name))])]),_c('p',{staticClass:"mb-2 jackpot-dollars"},[(event.status !== 'pending')?_c('strong',[_vm._v(_vm._s(_vm.formatCurrency(event.jackpot))+" raised")]):_vm._e()]),(event.licenseNumber)?_c('p',{staticClass:"mb-2"},[_c('strong',[_vm._v("License:")]),_vm._v(" "+_vm._s(event.licenseNumber))]):_vm._e()]),_c('div',[(event.status === 'active')?_c('div',{staticClass:"mt-auto"},[_c('p',{staticClass:"mb-2 event-status"},[_vm._v("Ticket sales end in "+_vm._s(_vm.formatDistance(_vm.parseISO(event.endDate))))]),_c('EventStatusBadge',{attrs:{"status":"active"}})],1):_vm._e(),(event.status === 'ended')?_c('div',[_c('p',{staticClass:"mb-2 event-status"},[_vm._v("Ticket sales ended "+_vm._s(_vm.formatDateTime(_vm.parseISO(event.endDate))))]),_c('EventStatusBadge',{attrs:{"status":"ended"}})],1):_vm._e(),(event.status === 'pending')?_c('div',{staticClass:"mt-auto"},[_c('EventStatusBadge',{attrs:{"status":"pending"}})],1):_vm._e(),(event.status === 'closed')?_c('div',{staticClass:"mt-auto"},[_c('p',{staticClass:"mb-2 event-status"},[_vm._v("Ticket sales ended "+_vm._s(_vm.formatDateTime(_vm.parseISO(event.endDate))))]),_c('EventStatusBadge',{attrs:{"status":"closed"}})],1):_vm._e()])]),(
              (event.status === 'ended' && event.winningTicket && !event.winningTicket.number) ||
              event.status === 'pending'
            )?_c('b-card-footer',{class:_vm.footerType(event.status)},[(event.status === 'ended' && !event.winningTicket.number)?_c('div',[_c('small',[_c('b-icon-exclamation-triangle'),_vm._v(" Raffle has ended, please select the winner ")],1)]):_vm._e(),(event.status === 'pending')?_c('div',[_c('small',[_c('b-icon-pencil-square'),_vm._v(" Edit and activate the raffle to sell tickets ")],1)]):_vm._e()]):_vm._e()],1)],1)}),1)],1),(_vm.pagination && !_vm.tableView)?_c('div',{staticClass:"mb-2"},[_vm._v(" Total Rows: "),_c('b',[_vm._v(" "+_vm._s(_vm.pagination.total))])]):_vm._e(),(_vm.pagination && !_vm.tableView)?_c('Pagination',{staticClass:"mt-10",attrs:{"pages":_vm.pagination.totalPages,"currentPage":_vm.currentPage},on:{"updateCurrentPage":_vm.updateCurrentPage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }