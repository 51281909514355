<template>
  <div>
    <b-button v-b-modal.create-raffle-modal variant="outline-secondary">
      <span class="fas fa-plus pr-1"></span>
      Create
    </b-button>

    <b-modal
      id="create-raffle-modal"
      title="Create a Rafflebox!"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="lg"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="createRaffleFailed" class="alert alert-danger">Failed to create raffle: {{ errorMessage }}</div>
        <EventNameInput ref="eventNameInput" v-model="event.name" />
        <EventLicenseNumberInput ref="eventLicenseNumberInput" v-model="event.licenseNumber" />
        <EventLicenseUploadInput
          :licenseNumber="event.licenseNumber"
          :organizationId="organization.id"
          @setLicenseUrl="setLicenseUrl"
          v-if="showLicenseUpload"
        />

        <EventShortlinkInput ref="eventShortlinkInput" v-model="event.shortlink" />

        <b-row>
          <b-col>
            <EventCategorySelect ref="eventCategorySelect" v-model="event.category" />
          </b-col>
        </b-row>
        <EventTimeInput ref="eventTimeInput" :province="event.province" />
        <EventFrequencySelect ref="eventFrequencySelect" v-model="event.frequency" />
        <DrawLocationInput ref="drawLocationInput" v-model="event.drawLocation" />
        <b-form-group label-for="show-rules-button">
          Show on Live Raffles?
          <b-form-checkbox v-model="event.showLiveRaffles" name="show-live-raffles-button" switch> </b-form-checkbox>
        </b-form-group>
        <b-form-group label-for="allow-invenue-sales-button">
          Allow In-Venue Sales
          <b-form-checkbox v-model="event.allowInVenueSales" name="allow-invenue-sales" switch> </b-form-checkbox>
        </b-form-group>
      </b-form>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-btn class="mr-2" @click="cancel()">Cancel</b-btn>
        <b-button v-if="creatingEvent" variant="primary" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </b-button>
        <b-btn v-else variant="primary" class="mr-2" @click="ok()">Create</b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import EventLicenseNumberInput from '@/components/inputs/EventLicenseNumberInput';
import EventLicenseUploadInput from '@/components/inputs/EventLicenseUploadInput';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import EventServiceV2 from '@/lib/event-service-v2';
import CategoryService from '@/lib/category-service';
import EventNameInput from '@/components/forms/EventNameInput';
import EventTimeInput from '@/components/forms/EventTimeInput';
import EventShortlinkInput from '@/components/forms/EventShortlinkInput';
import EventCategorySelect from '@/components/forms/EventCategorySelect';
import EventFrequencySelect from '@/components/forms/EventFrequencySelect';
import ProvinceStateService from '@/lib/province-state-view-service';
import DrawLocationInput from '@/components/forms/DrawLocationInput';

export default {
  components: {
    EventNameInput,
    EventTimeInput,
    EventShortlinkInput,
    EventCategorySelect,
    EventLicenseNumberInput,
    EventLicenseUploadInput,
    EventFrequencySelect,
    DrawLocationInput
  },
  data() {
    return {
      modalShow: false,
      createRaffleFailed: false,
      errorMessage: null,
      organization: {
        shortlink: null
      },
      event: {
        name: null,
        drawLocation: null,
        shortlink: null,
        timeZone: null,
        province: null,
        startDate: null,
        endDate: null,
        drawDate: null,
        category: null,
        startingTicketNumber: null,
        startingPot: null,
        frequency: 'once',
        allowInVenueSales: false,
        licenseNumber: null,
        licenseUrl: null
      },
      licenseFile: null,
      licenseName: null,
      showLiveRaffles: true,
      provinceOptions: null,
      categoryOptions: null,
      creatingEvent: false,
      sessionUser: null,
      isRbAdmin: false,
      isRaffleAdmin: false,
      isAccountOwner: false,
      showLicenseUpload: false,
      isUploading: false
    };
  },
  watch: {
    'event.licenseNumber': function () {
      if (this.event.licenseNumber && this.event.licenseNumber.length >= 5 && this.canUploadLicense()) {
        this.showLicenseUpload = true;
      } else {
        this.showLicenseUpload = false;
      }
    }
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  async created() {
    this.sessionUser = await getAuth().sessionUser();
    this.isRbAdmin = this.sessionUser.hasRole(Roles.RB_ADMIN);
    this.isRaffleAdmin = this.sessionUser.hasRole(Roles.RAFFLE_ADMIN);
    this.isAccountOwner = this.sessionUser.hasRole(Roles.ACCOUNT_OWNER);
  },
  async mounted() {
    const user = await getAuth().sessionUser();
    const organization = await OrganizationServiceV2.retrieveOrganization(user.organizationUuid);

    this.organization.id = organization.id;
    this.provinceOptions = ProvinceStateService.getCreateRaffleModalV2View(organization.country);
    this.categoryOptions = await CategoryService.listCategories();
  },
  methods: {
    canUploadLicense() {
      return this.isRbAdmin || this.isRaffleAdmin || this.isAccountOwner;
    },
    setLicenseUrl(licenseUrl) {
      this.event.licenseUrl = licenseUrl;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async resetForm() {
      this.modalShow = false;
      this.createRaffleFailed = false;
      this.errorMessage = null;

      this.event = {
        name: null,
        drawLocation: null,
        shortlink: this.organization.shortlink,
        timeZone: null,
        province: null,
        startDate: null,
        endDate: null,
        drawDate: null,
        category: '50/50',
        startingTicketNumber: 1000,
        startingPot: 0,
        frequency: 'once',
        showLiveRaffles: true,
        allowInVenueSales: false,
        licenseNumber: null,
        licenseUrl: null
      };

      // always need to fetch the org in case Raffle Admin is switching between them.
      // otherwise the province/short link would never change
      const organization = await OrganizationServiceV2.retrieveOrganization(this.sessionUser.organizationUuid);

      // Ontario specific requirements require us to disable province and drawType options
      if (organization.province === 'ON') {
        this.provinceOptions = ['ON'];
        this.categoryOptions = ['50/50', 'Raffle'];
      }

      this.event.province = organization.province;

      this.event.shortlink = organization.shortlink;
      this.timeZone = organization.timeZone;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      this.event.startDate = this.$refs.eventTimeInput.startDateTime;
      this.event.endDate = this.$refs.eventTimeInput.endDateTime;
      this.event.drawDate = this.$refs.eventTimeInput.drawDateTime;

      const formValid = await this.$validator.validateAll();
      const datesValid = this.$refs.eventTimeInput.isValid();
      const shortlinkValid = this.$refs.eventShortlinkInput.isValid();
      const eventNameValid = this.$refs.eventNameInput.isValid();
      const eventCategoryValid = this.$refs.eventCategorySelect.isValid();
      const eventLicenseNumberValid = this.$refs.eventLicenseNumberInput.isValid();
      const eventFrequencyValid = this.$refs.eventFrequencySelect.isValid();
      const drawLocationValid = this.$refs.drawLocationInput.isValid();

      if (
        !formValid ||
        !datesValid ||
        !shortlinkValid ||
        !eventNameValid ||
        !eventCategoryValid ||
        !eventLicenseNumberValid ||
        !eventFrequencyValid ||
        !drawLocationValid
      ) {
        return;
      }

      this.creatingEvent = true;

      const event = {
        name: this.event.name,
        shortlink: this.event.shortlink,
        category: this.event.category,
        province: this.event.province,
        startDate: this.event.startDate ? this.event.startDate : undefined,
        endDate: this.event.endDate,
        drawLocation: this.event.drawLocation,
        drawDate: this.event.drawDate,
        licenseNumber: this.event.licenseNumber,
        frequency: this.event.frequency,
        showLiveRaffles: this.event.showLiveRaffles,
        allowInVenueSales: this.event.allowInVenueSales
      };

      // For some reason we cannot send this property if it is null or empty
      if (this.event.licenseUrl !== null) {
        event.licenseUrl = this.event.licenseUrl;
      }

      try {
        const id = await EventServiceV2.createEvent(event);
        this.modalShow = false;
        if (this.$router.push) {
          this.$router.push({ path: '/raffle', query: { id } });
        }
      } catch (error) {
        this.createRaffleFailed = error;
        this.creatingEvent = false;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    }
  }
};
</script>
